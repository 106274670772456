<script>
  import LocaleSelect from "$lib/LocaleSelect.svelte";
  import SettingsModal from "$lib/SettingsModal.svelte";
  import { _ } from "svelte-i18n";
  import { getTitles } from "$lib/search.js";
  import { settings_store, initialSettings } from "$lib/store.js";
  import { get } from "svelte/store";

  let isOpen = false;
  let old_setting_values = "";

  function openSettingsModal() {
    isOpen = true;
    old_setting_values = JSON.stringify(get(settings_store));
    document.body.style.overflow = "hidden";
  }

  function closeSettingsModal() {
    isOpen = false;
    document.body.style.overflow = "";
    const new_values = JSON.stringify($settings_store);
    if (old_setting_values !== new_values) {
      getTitles();
    }
  }
  let settingIsDefault = true;
  $: {
    const same_period =
      $settings_store.production_period.length === initialSettings.production_period.length &&
      $settings_store.production_period.every((value, index) => value === initialSettings.production_period[index]);

    const same_subtitle_languages =
      $settings_store.subtitle_languages.length === initialSettings.subtitle_languages.length &&
      $settings_store.subtitle_languages.every((value, index) => value === initialSettings.subtitle_languages[index]);

    const same_audio_languages =
      $settings_store.audio_languages.length === initialSettings.audio_languages.length &&
      $settings_store.audio_languages.every((value, index) => value === initialSettings.audio_languages[index]);

    settingIsDefault = same_period && same_subtitle_languages && same_audio_languages;
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="header">
  <img src="/logos/mowizz_logo_beta.svg" alt="Mowizz logo" class="logo" width="23" height="8" />
  <div class="settings">
    <button
      class={settingIsDefault ? "filter-button" : "changed-filter-button"}
      title={$_("filter-settings")}
      alt="menu item: filter settings"
      on:click={() => openSettingsModal()}
      on:keypress={() => openSettingsModal()} />
    <LocaleSelect />
  </div>
</div>
<SettingsModal {isOpen} on:close={closeSettingsModal} />

<!-- ---------------------------------------------------------------------- -->

<style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    background-color: var(--black);
    color: var(--white);
    width: 90%;
    transition: all 0.3s;
  }
  .logo {
    display: inline-block;
    width: 11.3em; /* beta logo , original logo is 9.6em; */
    height: auto;
    object-fit: cover;
    object-position: -18px +3px;
  }
  .settings {
    display: flex;
    gap: 12px;
    height: 48px;
    align-items: center;
  }
  .filter-button {
    background: url("/buttons/btn_settings.svg") no-repeat;
    cursor: pointer;
    border: none;
    height: 48px;
    aspect-ratio: 1 / 1;
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  .filter-button:hover {
    background: url("/buttons/btn_settings_hover.svg") no-repeat;
  }
  .changed-filter-button {
    background: url("/buttons/btn_settings_changed.svg") no-repeat;
    cursor: pointer;
    border: none;
    height: 48px;
    aspect-ratio: 1 / 1;
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  .changed-filter-button:hover {
    background: url("/buttons/btn_settings_changed_hover.svg") no-repeat;
  }
  @media (max-width: 480px) {
    .header {
      width: 98%;
    }
  }
</style>
