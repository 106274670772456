<script>
  import { _, locale } from "svelte-i18n";
  import { slide } from "svelte/transition";
  import { consentModalOpen, settings_store } from "$lib/store.js";
  import LocaleSelect from "$lib/LocaleSelect.svelte";
  import MarkdownModal from "./MarkdownModal.svelte";

  let acceptAdCookies = true;
  let source = "# Loading Text ...";
  let markdownIsOpen = false;

  function openMarkdownModal(file) {
    fetch("/legal/" + $locale + "." + file + ".md")
      .then((response) => response.text())
      .then((text) => {
        source = text;
      });
    markdownIsOpen = true;
    document.body.style.overflow = "hidden"; // Disables scrolling on the body
  }

  function closeMarkdownModal() {
    markdownIsOpen = false;
    document.body.style.overflow = "";
  }

  function save_CookiePreference(acceptAll) {
    console.log("acceptAll: ", acceptAll);
    if (acceptAll) {
      acceptAdCookies = true;
    }
    console.log("save all cookies to svelte store");
    settings_store.update((current) => {
      return {
        ...current,
        consent_necessary_cookies: true,
        consent_ad_cookies: acceptAdCookies
      };
    });
    consentModalOpen.set(false);
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="cookie-consent-container" transition:slide={{ y: 300, duration: 500 }}>
  <img src="/logos/mowizz_logo_beta.svg" alt="Mowizz logo" class="logo" />
  <div class="locale">
    <LocaleSelect />
  </div>
  <div class="cookie-consent">
    <h1>{$_("cookie_consent_h1")}</h1>
    <p class="lead">{$_("cookie_consent_lead")}</p>
    <p class="list-item">{$_("cookie_consent_p1")}</p>
    <div class="list-item">
      <label class="switch" for="cookie_necessary">
        <input type="checkbox" id="cookie_necessary" name="necessary cookies" checked disabled />
        <span class="slider"></span>
      </label>
      <div>
        <span class="strong">{$_("necessary_cookies")}</span>
        {$_("cookie_consent_necessary")}
      </div>
    </div>
    <div class="list-item">
      <label class="switch" for="cookie_ads">
        <input type="checkbox" id="cookie_ads" name="ads cookies" bind:checked={acceptAdCookies} />
        <span class="slider"></span>
      </label>
      <div>
        <span class="strong">{$_("advertisement_cookies")}</span>
        {$_("cookie_consent_ads")}
      </div>
    </div>
    <button
      class="accept-button"
      on:click={() => save_CookiePreference(true)}
      on:keypress={() => save_CookiePreference(true)}>{$_("cookie_consent_button_accept")}</button>
    <button on:click={() => save_CookiePreference(false)} on:keypress={() => save_CookiePreference(false)}
      >{$_("cookie_consent_button_save")}</button>
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("pp")}
      on:keypress={() => openMarkdownModal("pp")}>
      {$_("cookie_consent_pp")}
    </div>
  </div>
</div>
<MarkdownModal {source} bind:isOpen={markdownIsOpen} on:close={closeMarkdownModal} />

<!-- ---------------------------------------------------------------------- -->

<style>
  .cookie-consent-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh; /* Ensure at least the viewport height */
    z-index: 2000;
    background: var(--true-black-60);
    overflow-y: scroll;
  }
  .logo {
    display: inline-block;
    width: 11.3em; /* beta logo , original logo is 9.6em; */
    object-fit: cover;
    position: fixed;
    top: -2px;
    left: 5%;
    object-position: -18px +3px;
    transition: all 0.2s;
  }
  .cookie-consent {
    max-width: 720px;
    margin: 172px 2% auto 2%;
    background-color: var(--black);
    padding: 4% 5% 2% 5%;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .cookie-consent :global(h1) {
    color: var(--mowizz-logo-blue);
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 100%;
    width: 100%;
    font-size: min(7.5vw, 48px);
  }
  .cookie-consent :global(h2) {
    color: var(--mowizz-logo-blue);
    font-size: 22px;
    font-weight: 300;
    margin-top: 0;
  }
  .lead {
    color: var(--mowizz-logo-blue);
    font-size: min(4.5vw, 1.5rem);
    margin-top: 1.5rem;
    margin-bottom: 5%;
  }
  p {
    color: var(--light-grey);
  }
  .list-item {
    color: var(--white-grey);
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
  }
  .locale {
    position: fixed;
    top: 2px;
    right: 5%;
    margin-right: 0px;
    transition: all 0.3s;
    z-index: 2001;
  }
  button {
    background-color: var(--dark-grey);
    color: var(--light-grey);
    height: 60px;
    float: right;
    font-size: 1em;
    padding: 1em 2em;
    margin-top: 8px;
    margin-left: 8px;
    border-radius: 12px;
    border-width: 1px;
    border-color: var(--black-80);
    cursor: pointer;
    border: 1px solid transparent;
  }
  @media (max-width: 480px) {
    .cookie-consent {
      margin-top: 142px;
      margin-bottom: auto;
    }
    button {
      width: 100%;
    }
    .logo {
      left: 5px;
    }
    .locale {
      right: 5px;
    }
  }
  button:hover {
    color: var(--light-grey);
    border: 1px solid var(--light-grey);
  }
  button.accept-button {
    color: var(--black);
    background-color: var(--mowizz-logo-blue);
    font-weight: 600;
  }
  button.accept-button:hover {
    color: var(--white);
    background-color: var(--mowizz-logo-dark-blue);
    border: 1px solid var(--white);
  }
  .footer-menu-item {
    color: var(--light-grey);
    margin-right: 8px;
    margin-left: 24px;
    margin-top: 12px;
    width: 100%;
    float: right;
    text-align: right;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .footer-menu-item:hover {
    color: var(--white-grey);
  }
  /* iOS styled slider-switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    min-width: 60px;
    height: 34px;
    margin-bottom: auto;
    margin-right: 1rem;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    transition: 0.3s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: var(--mowizz-logo-blue);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--mowizz-logo-blue);
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:disabled + .slider {
    background-color: var(--mowizz-logo-blue-20); /* Grey background for disabled slider */
    cursor: not-allowed;
  }
  input:disabled + .slider:before {
    background-color: var(--white-10); /* Optional: Change knob color for disabled state */
  }
  .strong {
    font-weight: 600;
  }
</style>
