<!-- Documentation link: https://kit.svelte.dev/docs/routing#layout-layout-svelte -->
<script>
  import "@fontsource-variable/inter";
  import "./global.css";
  import Header from "$lib/Header.svelte";
  import ConsentModal from "$lib/ConsentModal.svelte";
  import { locale } from "svelte-i18n";
  import { browser } from "$app/environment";
  import { supportedLanguages, defaultLanguage } from "$lib/LocaleConstants.js";
  import { onMount } from "svelte";
  import { settings_store, random_button_initiated } from "$lib/store.js";
  import { PUBLIC_MATOMO_SITE_ID } from "$env/static/public";
  import { consentModalOpen } from "$lib/store.js";

  const browser_language = browser ? window.navigator.language.split("-")[0] : defaultLanguage;
  $locale = supportedLanguages.includes(browser_language) ? browser_language : defaultLanguage;

  let can_persist_store = false;
  $: if (can_persist_store && $settings_store) {
    window.localStorage.setItem("settings_store", JSON.stringify($settings_store));
    console.log("write persisted_settings to windows store", $settings_store); // FIXME: debug logging. remove after stabilization
  }

  onMount(() => {
    // show random button only once, then store in localStorage, that the user has seen it
    const storedRandomButtonState = localStorage.getItem("random_button_initiated");
    if (storedRandomButtonState !== null) {
      try {
        // const RandommButtonInitiated = JSON.parse(storedRandomButtonState);
        console.log("RandommButtonInitiated: " + $random_button_initiated);
        random_button_initiated.set(JSON.parse(storedRandomButtonState));
      } catch (e) {
        console.error("Error parsing stored value:", e);
        // Handle error or fallback scenario
      }
    } else {
      random_button_initiated.set(JSON.parse(false));
      console.log("RandommButtonInitiated else: " + $random_button_initiated);
    }
    // only once:
    loadPersistedSettings();
    initializeMatomo();
  });

  function loadPersistedSettings() {
    const persisted_settings = JSON.parse(window.localStorage.getItem("settings_store"));
    console.log("read persisted_settings from windows store", persisted_settings); // FIXME: debug logging. remove after stabilization
    if (persisted_settings) {
      const persisted_settings_keys = JSON.stringify(Object.keys(persisted_settings));
      const settings_store_keys = JSON.stringify(Object.keys($settings_store));
      if (persisted_settings_keys === settings_store_keys) {
        $settings_store = persisted_settings;
      }
    }
    can_persist_store = true;
    $locale = $settings_store.selected_locale;
    consentModalOpen.set(!$settings_store.consent_necessary_cookies); //necessary cookies not yet accepted -> show modal
  }

  function initializeMatomo() {
    let _paq = (window._paq = window._paq || []);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      let baseUrl = "https://matomo.mowizz.com/";
      _paq.push(["setTrackerUrl", baseUrl + "matomo.php"]);
      _paq.push(["setSiteId", "" + PUBLIC_MATOMO_SITE_ID]);
      let d = document;
      let script = d.createElement("script");
      script.async = true;
      script.src = baseUrl + "matomo.js";
      d.head.appendChild(script);
    })();
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<svelte:head>
  <title>Mowizz</title>
</svelte:head>
<div class="app">
  <div class="header-container">
    <Header />
  </div>
  <slot />

  {#if $consentModalOpen}
    <ConsentModal />
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .app {
    display: flex;
    flex-direction: column;
  }

  .header-container {
    position: sticky;
    top: 0;
    z-index: 110;
    display: flex;
    justify-content: center;
    background-color: var(--black);
  }
</style>
